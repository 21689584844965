@import "./variables";
@import "./buttons";
@import "./theme.scss";

body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  color: $black;
}

button {
  border: none !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a:hover {
  color: $primary !important;
}
.currency {
  background-color: $primary;
  color: $white;
  position: absolute;
}
.navbar a:hover {
  color: $primary !important;
}
/* hero styles  */
#hero {
  height: auto !important;
  padding: 0 !important;
  margin-top: 86px;
}
@media (min-width: 768px) {
  #hero {
    min-width: 100%;
    height: 95px;
  }
}

#calc-table-head {
  // background: $primary;
  // background: -moz-linear-gradient(
  //   left,
  //   rgba(63, 166, 75, 1) 0%,
  //   rgba(40, 100, 86, 1) 100%
  // );
  // background: -webkit-linear-gradient(
  //   left,
  //   rgba(63, 166, 75, 1) 0%,
  //   rgba(40, 100, 86, 1) 100%
  // );
  // background: linear-gradient(
  //   to right,
  //   rgba(63, 166, 75, 1) 0%,
  //   rgba(40, 100, 86, 1) 100%
  // );
  background-color: $primary !important;
  color: white;
  vertical-align: middle !important;
  text-align: center !important;
  font-weight: normal !important;
}

#calc-table-head th {
  padding: 10px 22px;
}

#tableBody td {
  padding: 10px 22px;
  word-wrap: break-word;
}

@media only screen and (max-width: 600px) {
  #calc-table-head th {
    padding: 0px 0px;
    font-size: 12px;
  }
  #tableBody td {
    font-size: 10px;
  }
}

// footer
footer * {
  background-color: $footer-bg !important;
  color: white !important;
}
#footer .footer-contact,
#footer .container,
#footer .row,
#footer .footer-links ul a {
  color: $light-gray2 !important;
}
#footer .footer-links ul a:hover {
  color: $primary !important;
}

.theme-toggle * {
  font-size: 2rem;
}

.hero-bg-image {
  height: 50vh;
  margin: auto;
  max-width: 150%;
  margin-left: 50%;
  transform: translateX(-50%);
  object-fit: cover;
  height: auto;
}

@media (min-width: 768px) {
  .hero-bg-image {
    min-width: 100%;
    height: 45vh;
  }
}

// services
.icon-box > h5 > a {
  font-weight: bold;
  color: $black !important;
}
.services .icon-box .icon svg path {
  fill: $light-gray;
}

.service-card {
  cursor: pointer;
  .icon {
    border-color: $primary;
    border-width: 1px;
    border-style: solid;
    border-radius: 100%;
    margin-bottom: 1rem !important;
  }
}

/* mobile nav */
.mobile-nav-toggle {
  color: $primary;
}

/* dark mode start */
html.dark {
  body,
  p,
  h1,
  label,
  span,
  tr,
  td,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    color: white !important;
  }

  .header-social-links a:hover {
    color: $primary !important;
  }

  #calc-table-head {
    background-color: $primary !important;
  }

  .container,
  body,
  .row,
  .col,
  .card,
  .about-us,
  .navbar-mobile ul,
  .header,
  form,
  table,
  tbody,
  thead,
  .modal-content,
  #header {
    background-color: $bg-gray !important;
  }

  .btn-close {
    color: white;
  }

  .icon-box,
  .card,
  .card-body,
  .pagination .page-link {
    color: white !important;
    background-color: rgb(41, 45, 51) !important;
  }

  .pagination .active .page-link {
    color: $primary !important;
  }

  .icon-box > p {
    color: rgb(169, 176, 187) !important;
  }
  .icon-box > a {
    color: $primary !important;
  }

  .blog-post h6 {
    color: white !important;
  }

  .blog-post p {
    color: rgb(169, 176, 187) !important;
  }

  .blog-post__date {
    color: white;
  }

  footer {
    .container,
    .row {
      background-color: $footer-bg !important;
    }
  }
  .box input[type="text"],
  .box input[type="password"] {
    border: 0;
    background: none;
    display: block;
    margin: 20px auto;
    text-align: center;
    border: 2px solid white;
    padding: 10px 10px;
    width: 250px;
    outline: none;
    color: white;
    border-radius: 24px;
    transition: 0.25s
  }

  .box h1 {
    color: white;
    font-weight: 500
  }
}

/* dark mode end */

/* services styles */
section#services {
  height: 100%;
}
#services {
  padding-top: 0;
  padding-bottom: 0;
}
/* @media (min-width: 786px) {
  #services {
    transform: translateY(-25%);
    margin-bottom: -25%;
  }
} */

.filter-green {
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg)
    brightness(119%) contrast(119%);
}
/* blogs */
.blog-post-img {
  width: auto;
  height: 45vh;
  object-fit: cover;
}

.blog-post {
  a {
    border: none;
  }
  a:hover {
    color: white !important;
  }
}

.blog-post__date {
  color: rgb(41, 45, 51) !important;
}

.blog-post__btn {
  color: white !important;
}

@media (min-width: 786px) {
  .blog-post-img {
    height: 30vh;
  }
}
.page-item.active .page-link {
  border-color: $primary !important;
}

/* зээлийн хүсэлт товч*/
#btnTop > button {
  background-color: $primary;
  padding: 0.5rem 1rem;
  font-weight: bold;
  bottom: 1rem !important;
  right: 1rem !important;
}

@media (max-width: 768px) {
  .card {
    margin-bottom: 20px;
    border: none
  }

  .box {
    width: 100%;
    padding: 40px;
    text-align: center;
    transition: 0.25s;
    margin-top: 100px
  }
}
// Desktop
@media (min-width: 769px) {
  .card {
    margin-bottom: 20px;
    border: none
  }

  .box {
    width: 100%;
    padding: 40px;
    text-align: center;
    transition: 0.25s;
    margin-top: 100px
  }
}

.box input[type="text"],
.box input[type="password"] {
  border: 0;
  background: none;
  display: block;
  margin: 20px auto;
  text-align: center;
  border: 2px solid black;
  padding: 10px 10px;
  width: 250px;
  outline: none;
  color: black;
  border-radius: 24px;
  transition: 0.25s
}

.box h1 {
  color: black;
  font-weight: 500
}

.box input[type="text"]:focus,
.box input[type="password"]:focus {
  width: 200px;
  border-color: $primary
}

.box input[type="submit"] {
  border: 0;
  background: $primary;
  display: block;
  margin: 20px auto;
  text-align: center;
  padding: 14px 40px;
  outline: none;
  color: white;
  border-radius: 24px;
  transition: 0.25s;
  cursor: pointer
}

.forgot {
  text-decoration: underline
}