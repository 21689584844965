/* semantic colors */
$primary: #2daa8d;
$warning: rgb(245, 185, 69);
$danger: rgb(250, 108, 81);
$light-gray: rgb(244, 246, 248);
$light-gray2: rgb(169, 176, 187);
$black: rgb(66, 72, 82);
$gray1: rgb(99, 108, 119);

/* dark */

/* light */
$white: #fff;

$bg-light: #fff;

$bg-dark: rgb(41, 45, 51);
$bg-gray: rgb(53, 58, 66);

$footer-bg: $bg-dark;
